
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  getCurrentInstance,
  PropType,
  ComputedRef,
} from "vue";
import { useI18n } from "vue-i18n";
import { FbmOrder } from "@/core/types/FbmOrderTypes";
import {
  ShippingStatusChangeRequests,
  ShippingPackageInfoDto,
  ShippingStatusChangeRequestDto,
  ShippingStatusChangeResponse,
} from "@/core/types/SP_API_ShippingTypes";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "fbmorder-unshipped-listtable",
  emit: ["current-change", "sort", "items-per-page-change"],
  props: {
    tableData: {
      type: Object as PropType<Array<FbmOrder>>,
      required: true,
    },
    emptyTableText: { type: String, default: "No data found" },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 25 },
    order: { type: String, default: "asc" },
    sortLabel: { type: String, default: "" },
  },
  components: {},
  setup(props, { emit }) {
    const store = useStore();
    const data = ref(props.tableData);
    const currentSort = ref<string>("");
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const pagination = ref<IPagination>({
      page: 1,
      total: props.total,
      rowsPerPage: props.rowsPerPage,
    });
    const { d } = useI18n();
    const vnodeProps = getCurrentInstance()?.vnode.props || {};
    const confirmShipmentsButton = ref<HTMLButtonElement | null>(null);
    const shippingPackageInfoDto = ref<ShippingPackageInfoDto>({
      orderItemId: "",
      trackingNumber: "",
    });
    const shippingStatusChangeRequestDto = ref<ShippingStatusChangeRequestDto>({
      amazonOrderId: "",
      packages: [] as ShippingPackageInfoDto[],
    });
    const shippingStatusChangeRequests = ref<ShippingStatusChangeRequests>({
      shippingStatusRequestList: [],
    });
    const shippingStatusChangeResponse: ComputedRef<ShippingStatusChangeResponse> =
      computed(() => {
        return store.getters.getShippingStatusChangeResponse;
      });
    const shippingStatusChangeRequestDtoArray = ref(
      [] as ShippingStatusChangeRequestDto[]
    );
    const allOrdersSelected = ref(false);
    const selectedOrders = ref([]);
    watch(data.value, () => {
      if ("onCurrentChange" in vnodeProps) {
        currentSort.value = label.value + order.value;
      } else {
        pagination.value.total = data.value.length;
      }
    });

    onMounted(() => {
      pagination.value.total = props.total ? props.total : data.value.length;
      pagination.value.rowsPerPage = props.rowsPerPage;
    });

    const getItems: ComputedRef<Array<FbmOrder>> = computed(() => {
      if ("onCurrentChange" in vnodeProps) {
        return filterData.value;
      } else {
        const clone = JSON.parse(JSON.stringify(filterData.value));
        const startFrom =
          pagination.value.page * pagination.value.rowsPerPage -
          pagination.value.rowsPerPage;
        return clone.splice(startFrom, pagination.value.rowsPerPage);
      }
    });
    const search = ref("");
    const filterData = computed(() => {
      return data.value.filter((user) => {
        return (
          user.buyerEmail.toLowerCase().indexOf(search.value.toLowerCase()) !=
            -1 || user.amazonOrderId.indexOf(search.value) != -1
        );
      });
    });

    const currentPageChange = (val) => {
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.value.page = val;
      }
    };

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.value.rowsPerPage = parseInt(event.target.value);
      }
    };

    function orderSelected(amazonOrderId, packages) {
      console.log("AmazonOrderId: " + amazonOrderId);
      console.log("Packages: " + packages.length);
      const newShippingStatusChangeRequestDto =
        ref<ShippingStatusChangeRequestDto>({
          amazonOrderId: "",
          packages: [] as ShippingPackageInfoDto[],
        });
      newShippingStatusChangeRequestDto.value.amazonOrderId = amazonOrderId;

      let length = packages.length;

      for (var i = 0; i < length; i++) {
        console.log("loop: " + i);
        const newShippingPackageInfoDto = ref<ShippingPackageInfoDto>({
          orderItemId: "",
          trackingNumber: "",
        });
        newShippingPackageInfoDto.value.trackingNumber =
          packages[i].trackingNumber;
        newShippingPackageInfoDto.value.orderItemId = packages[i].orderItemId;
        newShippingStatusChangeRequestDto.value.packages.push(
          newShippingPackageInfoDto.value
        );
      }
      shippingStatusChangeRequests.value.shippingStatusRequestList.push(
        newShippingStatusChangeRequestDto.value
      );
    }

    function confirmShipments() {
      shippingStatusChangeRequests.value;
      store
        .dispatch(
          Actions.UPDATE_SHIPPING_STATUS,
          shippingStatusChangeRequests.value
        )
        .then(() => {
          //
          Swal.fire({
            text: "Shipments have been confirmed",
            icon: "success",
            showCloseButton: true,
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-info",
            },
          });
        })
        .catch(() => {
          Swal.fire({
            text: shippingStatusChangeResponse.value.errors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (confirmShipmentsButton.value) {
        // eslint-disable-next-line
        confirmShipmentsButton.value!.disabled = true;
        // Activate indicator
        confirmShipmentsButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    watch(shippingStatusChangeResponse, () => {
      if (shippingStatusChangeResponse.value.success) {
        selectedOrders.value = [];
      }
      shippingStatusChangeRequests.value.shippingStatusRequestList = [];
      //Deactivate indicator
      confirmShipmentsButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      confirmShipmentsButton.value!.disabled = false;
    });

    return {
      search,
      filterData,
      confirmShipmentsButton,
      allOrdersSelected,
      shippingPackageInfoDto,
      shippingStatusChangeRequestDto,
      shippingStatusChangeRequests,
      shippingStatusChangeResponse,
      shippingStatusChangeRequestDtoArray,
      selectedOrders,
      pagination,
      currentPageChange,
      getItems,
      setItemsPerPage,
      orderSelected,
      d,
      confirmShipments,
    };
  },
});
