
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  ComputedRef,
  watch,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import FBMUnshippedListTable from "@/components/datatable/FBMUnshippedListTable.vue";
import {
  FbmOrder,
  CheckedOrders,
  FbmOrdersFromSqlRequest,
} from "@/core/types/FbmOrderTypes";

export default defineComponent({
  name: "fbm-unshipped-orders",
  components: { FBMUnshippedListTable, TableSkeleton },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const isDataReady = ref(false);
    const fbmOrderList: ComputedRef<Array<FbmOrder>> = computed(() => {
      return store.getters.fbmOrdersFromSql;
    });
    const tableData = ref<Array<FbmOrder>>([]);
    const fbmOrdersFromSqlRequest = ref<FbmOrdersFromSqlRequest>({
      fromDate: "",
      shippingStatus: "Unshipped",
      shippingLabelPrinted: true,
    });
    const checkedFbmOrders = ref([] as CheckedOrders[]);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Unshipped FBM Orders", ["Online Orders"]);
    });
    store
      .dispatch(Actions.GET_FBM_ORDERS_FROM_SQL, fbmOrdersFromSqlRequest.value)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving orders.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    watch(fbmOrderList, () => {
      isDataReady.value = true;
    });

    return {
      currentUser,
      isDataReady,
      tableData,
      fbmOrdersFromSqlRequest,
      fbmOrderList,
      checkedFbmOrders,
    };
  },
});
